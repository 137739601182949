<template>
  <b-card class="action">
    <h6 class="text-muted mr-1">{{ T("Web.Generic.Locations.Locations") }}</h6>
    <b-form-select
      :placeholder="
        T('Web.Generic.Locations.SelectLocation', 'Select Location')
      "
      v-model="activeLocation"
      label="name"
      :options="options"
      name="locationPicker"
    />
    <div class="infocardType mt-1">
      <label
        :class="{ selected: infoCard.type == 'ContainerInfoCard' }"
        @click="infoCard.type = 'ContainerInfoCard'"
      >
        {{ T("Web.Generic.Infocards.Types.Container", "Container/Fraction") }}
      </label>

      <label
        :class="{ selected: infoCard.type == 'AccountInfoCard' }"
        @click="infoCard.type = 'AccountInfoCard'"
      >
        {{ T("Web.Generic.Infocards.Types.Account", "Account") }}
      </label>
    </div>
    <div class="mt-1">
      <label>{{T('Web.Generic.Title', 'Title')}}</label>
      <input
        type="text"
        placeholder="Title"
        v-model="infoCard.title"
        class="form-control"
      />
    </div>

    <div class="mt-1">
      <label>{{T('Web.Generic.Image', 'Image')}}</label>
      <input
        ref="fileupload"
        @change="setFiles"
        type="file"
        class="form-control"
      />
    </div>

    <div class="mt-2">
      <b-button
        :disabled="
          activeLocation == undefined ||
          infoCard.title == '' ||
          loading ||
          processingFile
        "
        @click="submit"
        class="w-100"
        variant="primary"
        >{{T('Web.Generic.Save', 'Save')}}</b-button
      >
      <span v-show="loading" class="loader-bg">
        <loader
          @loading="loading = true"
          @loadCompelete="loading = false"
          class="loader"
          ref="loader"
        ></loader>
      </span>
    </div>
  </b-card>
</template>
<script>
import { BCard, BButton, BRow, BCol, BFormSelect } from "bootstrap-vue";
import Index from "../economics/agreementBasis/index.vue";
import loader from "@/components/layout/loader.vue";
import LocationPickerBar from "@/components/LocationPickerBar.vue";
import { mapActions, mapGetters } from "vuex";
import ResourceService from "@/services/base/resource.service";
import imageCompressor from "vue-image-compressor";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    Index,
    loader,
    BFormSelect,
    LocationPickerBar,
    imageCompressor,
  },
  async created() {
    await this.fetchLocations();
    this.services = new ResourceService("info-cards");
  },
  async mounted() {},
  data() {
    return {
      loading: false,
      services: null,
      infoCard: {
        type: "ContainerInfoCard",
        title: "",
        file: null,
      },
      activeLocation: undefined,
      scale: 25,
      quality: 70,
      reader: {},
      processingFile: false,
    };
  },
  computed: {
    ...mapGetters({
      locations: "locationsOld/list",
    }),
    options() {
      let list = this.locations.map((l) => ({ text: l.name, value: l }));
      return list;
    },
  },
  methods: {
    ...mapActions({
      fetchLocations: "locationsOld/fetchList",
    }),
    async submit() {
      this.$refs.loader.loading();
      this.$getLocation({
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }).then(
        async (coordinates) => {
          await this.submitForm(coordinates.lng, coordinates.lat);
        },
        async () => {
          await this.submitForm(null, null);
          alert(this.T('Web.CreationWizzard.Errors.CreatedButNoGeolocation', 'infocard created but could not get geolocation'));
        }
      );
    },
    async submitForm(lng, lat) {
      const formData = new FormData();

      formData.append("title", this.infoCard.title);
      formData.append("locationId", this.activeLocation.id);
      if (lat != null) formData.append("latitudestring", lat);
      if (lng != null) formData.append("longitudestring", lng);

      formData.append("type", this.infoCard.type);
      if (this.infoCard.file) {
        formData.append("file", this.infoCard.file, this.infoCard.file.name);
      }

      await this.services.create(formData);
      this.$refs.loader.loadCompelete();
      this.resetForm();
    },
    resetForm() {
      this.infoCard = {
        type: "ContainerInfoCard",
        title: "",
        file: null,
      };
      this.$refs.fileupload.value = null;
    },
    setFiles(e) {
      this.processingFile = true;
      // If There's no file choosen
      let file = e.target.files[0];
      if (!file) {
        this.processingFile = false;
        this.infoCard.file = null;
        return false;
      }
      // get the file
      this.file = e.target.files[0];
      // Validation
      let type = this.file.type;
      let valid = type.indexOf("image") !== -1;
      if (!valid) throw "File Type Is Not Supported. Upload an image instead";
      // Make new FileReader
      this.reader = new FileReader();
      // Convert the file to base64 text
      this.reader.readAsDataURL(this.file);
      // on reader load somthing...
      this.reader.onload = this.fileOnLoad;
    },
    async base64ToImage(base64) {
      return new Promise(function (resolved, rejected) {
        var i = new Image();
        i.src = base64;
        i.onload = function () {
          resolved(i);
        };
      });
    },
    async drawImage(fileInfo) {
      // Recreate Canvas Element
      let canvas = document.createElement("canvas");
      this.canvas = canvas;
      // Set Canvas Context
      let ctx = this.canvas.getContext("2d");
      // Create New Image
      let img = await this.base64ToImage(fileInfo.base64);

      // Image Size After Scaling
      let scale = this.scale / 100;
      let width = img.width * scale;
      let height = img.height * scale;

      // Set Canvas Height And Width According to Image Size And Scale
      this.canvas.setAttribute("width", width);
      this.canvas.setAttribute("height", height);
      ctx.drawImage(img, 0, 0, width, height);
      // Quality Of Image
      let quality = this.quality ? this.quality / 100 : 1;
      // If all files have been proceed
      let base64 = this.canvas.toDataURL(fileInfo.type, quality);

      let fileName = fileInfo.name;
      let lastDot = fileName.lastIndexOf(".");
      let ext = fileInfo.name.substr(fileInfo.name.lastIndexOf("."));
      fileName = fileName.substr(0, lastDot) + ext;

      let result = await this.buildFile(base64, fileName, fileInfo.type);

      //DONE
      this.infoCard.file = result;
      this.processingFile = false;
    },
    fileOnLoad() {
      // The File
      let { file } = this;
      // Make a fileInfo Object
      let fileInfo = {
        name: file.name,
        type: file.type,
        base64: this.reader.result,
      };
      // DrawImage
      this.drawImage(fileInfo);
    },
    // Convert Blob To File
    async buildFile(b64Data, name, type) {
      const base64Response = await fetch(b64Data);
      const blob = await base64Response.blob();
      return new File([blob], name, { type: type });
    },
    async getImageDimensions(file) {
      return new Promise(function (resolved, rejected) {
        var i = new Image();
        i.onload = function () {
          resolved({ w: i.width, h: i.height });
        };
        i.src = file;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.loader-bg {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #ccc;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 40px;
  top: 50%;
}
.action {
  position: relative;
}
.infocardType {
  display: flex;
  justify-content: space-between;
  > label {
    border-radius: 5px;
    width: 48%;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    &.selected {
      border: 1px solid #407e3d;
      color: "#407e3d ";
    }
  }
}
</style>